@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400);

.topBefore input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #f1e9e1;
  font-size: 0.875em;
}

.topBefore input:focus::-webkit-input-placeholder, .topBefore textarea:focus::-webkit-input-placeholder {
  color: #bbb5af;
}

.topBefore input::-moz-placeholder, .topBefore textarea::-moz-placeholder {
  color: #f1e9e1;
  font-size: 0.875em;
}

.topBefore input:focus::-moz-placeholder, .topBefore textarea:focus::-moz-placeholder {
  color: #bbb5af;
}

.topBefore input::placeholder, .topBefore textarea::placeholder {
  color: #f1e9e1;
  font-size: 0.875em;
}

.topBefore input:focus::placeholder, .topBefore textarea::focus:placeholder {
  color: #bbb5af;
}

.topBefore input::-ms-placeholder, .topBefore textarea::-ms-placeholder {
  color: #f1e9e1;
  font-size: 0.875em;
}

.topBefore input:focus::-ms-placeholder, .topBefore textarea:focus::-ms-placeholder {
  color: #bbb5af;
}

/* on hover placeholder */

.topBefore input:hover::-webkit-input-placeholder, .topBefore textarea:hover::-webkit-input-placeholder {
  color: #f5f2f0;
  font-size: 0.875em;
}

.topBefore input:hover:focus::-webkit-input-placeholder, .topBefore textarea:hover:focus::-webkit-input-placeholder {
  color: #cbc6c1;
}

.topBefore input:hover::-moz-placeholder, .topBefore textarea:hover::-moz-placeholder {
  color: #f5f2f0;
  font-size: 0.875em;
}

.topBefore input:hover:focus::-moz-placeholder, .topBefore textarea:hover:focus::-moz-placeholder {
  color: #cbc6c1;
}

.topBefore input:hover::placeholder, .topBefore textarea:hover::placeholder {
  color: #f5f2f0;
  font-size: 0.875em;
}

.topBefore input:hover:focus::placeholder, .topBefore textarea:hover:focus::placeholder {
  color: #cbc6c1;
}

.topBefore input:hover::placeholder, .topBefore textarea:hover::placeholder {
  color: #f5f2f0;
  font-size: 0.875em;
}

.topBefore input:hover:focus::-ms-placeholder, .topBefore textarea:hover::focus:-ms-placeholder {
  color: #cbc6c1;
}

/* body {
  font-family: 'Lato', sans-serif;
  background: #e2dedb;
  color: #b3aca7;
} */

.tituloForm {
  position: relative;
  margin: 0px 0 25px 0;
  font-size: 2.3em;
  text-align: center;
  letter-spacing: 7px;
}

#formularioCorreo {
  position: relative;
  width: 500px;
max-width: 90vw;
  margin: 20px auto 20px auto;
}

.topBefore input {
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  width: 500px;
max-width: 90vw;
  height: 50px;
  padding: 0px 15px 0px 15px;
  
  background: transparent;
  outline: none;
  color: white;
  
  border: solid 1px #b3aca7;
  border-bottom: none;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.topBefore input:hover {
  background: #b3aca752;
  color: #f5f2f0;
}

.topBefore textarea {
  width: 500px;
max-width: 90vw;
  max-width: 500px;
max-width: 90vw;
  height: 110px;
  max-height: 110px;
  padding: 15px;
  
  background: transparent;
  outline: none;
  
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  
  border: solid 1px #b3aca7;
  
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.topBefore textarea:hover {
  background: #b3aca752;
  color: #f5f2f0;
}

#submit {
  /* width: 502px; */
  
  padding: 0;
  margin: -5px 0px 0px 0px;
  
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  color: #f1e9e1;
  
  outline:none;
  cursor: pointer;
  
  border: solid 1px #d4ccc7;
  border-top: none;
}

#submit:hover {
  color: #f5f2f0;
}